<template>
    <Navbar/>

    <!-- Hero Area -->
	
	<div class="homepage-slides">
        <swiper
			:modules="modules"
			:slides-per-view="1"
			:space-between="0"
			navigation
			:pagination="{ clickable: true }"
			:autoplay="{ delay: 15000 }"
		>
            <swiper-slide>
                <div class="single-slide-item">
                    <div class="hero-area-content">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-12 wow fadeInUp animated" data-wow-delay=".3s">
                                    <div class="section-title">
										<h4 style="background-color: black;z-index:9999;max-width: 600px;height:1px;">
										</h4>
										<h4 style="background-color: #E6B0AA;z-index:9999;max-width: 600px;">
											PLEASE BE AWARE!<br/>
											BM CARE ARE NO LONGER OFFERING SPONSORSHIPS<br/>
											WE ARE AWARE THAT SCAMMERS ARE USING OUR NAME TO OFFER COS'S<br/> 
											PLEASE CONTACT US IMMEDIATELY AND VERIFY IF YOU HAVE RECIEVED ANY OFFER OF COS<br/>
											DO NOT CONTINUE WITH ANY OFFER UNLESS YOU VERIFY WITH OUR OFFICE FIRST<br/>
											CALL +44 1299 333 166
										</h4>
                                        <h1>We Strive to <br> Stand for <b >quality</b></h1>
                                        <p><b >Care is a process of giving professional and compassionate help and assistance, <br> to those that are in need.</b></p>
                                    </div>
                                    <router-link :to="{name:'About'}" class="main-btn transparent">Learn More</router-link>
                                    <!-- <router-link :to="{name:'Case'}" class="main-btn">View Cases</router-link> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>>

            <swiper-slide>
                <div class="single-slide-item hero-area-bg-2">			
                    <div class="hero-area-content">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-12 wow fadeInUp animated" data-wow-delay=".3s">
                                    <div class="section-title">
										<h4 style="background-color: black;z-index:9999;max-width: 600px;height:1px;">
										</h4>
										<h4 style="background-color: #E6B0AA;z-index:9999;max-width: 600px;">
											PLEASE BE AWARE!<br/>
											BM CARE ARE NO LONGER OFFERING SPONSORSHIPS<br/>
											WE ARE AWARE THAT SCAMMERS ARE USING OUR NAME TO OFFER COS'S<br/> 
											PLEASE CONTACT US IMMEDIATELY AND VERIFY IF YOU HAVE RECIEVED ANY OFFER OF COS<br/>
											DO NOT CONTINUE WITH ANY OFFER UNLESS YOU VERIFY WITH OUR OFFICE FIRST<br/>
											CALL +44 1299 333 166
										</h4>
                                        <h1>We are Certified <br> Care <b style="color: yellow">Professionals</b> </h1>
                                        <p style="color: yellow"><b>Care is a process of giving professional and compassionate help and assistance, <br> to those that are in need.</b></p>
                                    </div>
                                     <router-link :to="{name:'About'}" class="main-btn transparent">Learn More</router-link>
                                     <!-- <router-link :to="{name:'Case'}" class="main-btn">View Cases</router-link> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
	</div>

	<!-- About Section-->

	<div class="about-area section-padding ">
		<div class="container-fluid">
			<div class="row">
				<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay=".3s">
					<div class="about-img mb-50">
						<img src="assets/img/about/about-1.jpg" alt="">
						<div class="founded-year">
							<i class="flaticon-gavel">Founded in 2012</i>
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 wow fadeInUp" data-wow-delay=".4s">
					<div class="about-content-area">
						<div class="section-title">
							<h6>Introduction</h6>
							<h2>We pride ourselves in providing <b>Client Satisfaction</b></h2>
						</div>
						<p>We aim to provide high quality care, that is professional, compassionate and trustworthy.</p>
						<p class="highlight">Since our launch in Febuary 2012 we have proven that we care about our services.</p>
						<p class="highlight">We always provide 100% care and strive to keep up these standards.</p>
						<p class="highlight">With Vetted, Trained and Motivated staff, we have a team that can deliver dependable care.</p>
						<div class="founded">
							<div class="row no-gutters">
								<div class="col-lg-12 col-md-12 col-12">
									<div class="founded-text">
										<p>We have 10+ years Experience in Care Provisional Services.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Services Section -->

	<!-- <div class="services-area bg-cover section-padding pb-50">
        <div class="container">
            <div class="row">
                <div class="offset-lg-2 col-lg-8 text-center">
                    <div class="section-title">
                        <h6>Practice Areas</h6>
                        <h2>We Provide Various <br><b>Legal Services</b></h2>
                        <p class="text-white">Law Practice equipment modern technique sit amet, consectetur adipisicing elit. <br> Repellendus laboriosam omnis cum.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay=".2s" v-for="service in services" :key="service.id" >
                    <div class="single-service-area mb-70" :class="'service-'+service.id">
                        <div class="service-icon">
                            <i :class="service.icon"></i>
                        </div>
                        <h4>{{service.name}}</h4>
                        <p>{{service.short_description}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

	<!-- Case Studies -->

	<!-- <div class="case-area section-padding">
		<div class="container">
			<div class="offset-lg-1 col-lg-10 text-center">
				<div class="section-title">
					<h6>Featured Work</h6>
					<h2>Recent <b>Case Studies</b></h2>
					<p>Law Practice equipment modern technique sit amet, consectetur adipisicing elit. <br> Repellendus laboriosam omnis cum.</p>
				</div>
			</div>
			<div class="row">

				<div class="col-lg-4 col-md-6 col-12 wow fadeInLeft" data-wow-delay=".2s" v-for="c in cases" :key="c.id">
					<div class="single-case-item mb-50 bg-cover" :style="'background-image:url('+c.images.bg_image+')'">
						<div class="case-inner">
							<div class="hover-info">
								<h6>{{c.category}}</h6>
								<h4>{{c.short_title}}</h4>
							</div>
							<router-link class="case-icon" :to="{name:'CaseDetails', params:{ id: c.id} }">
								<i class="las la-plus"></i>
							</router-link>
							
							
						</div>
					</div>
				</div>				
			</div>
		</div>
	</div> -->

	<!-- Feature Section-->

	<div class="feature-area bg-cover sky-bg section-padding">
		<div class="container">
			<div class="row">
				<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay=".3s">
					<div class="section-title">
						<h6>Our Accreditation References</h6>
						<h2>We are accredited in the provision of high quality <b>care</b></h2>
					</div>
					<div class="single-feature-item" v-for="reason in choose_us.reasons" :key="reason.id">
						<div class="feature-icon">
							<a v-bind:href="reason.link"><img :src="reason.image" alt=""></a>
						</div>
						<div class="feature-content">
							<h5>{{reason.title}}</h5>
							<p>{{reason.short_description}}</p>
							<i style="color:#C89D66"><b>{{reason.link_description}}</b></i>
						</div>
					</div>				
				</div>
				<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 wow fadeInUp" data-wow-delay=".4s">
					<div class="feature-image">
						<img :src="choose_us.feature_image" alt="">
					</div>
				</div>
			</div>
		</div>
	</div>

	<!--CTA Section-->

	<!-- <div class="cta-area bg-cover">
		<div class="overlay-2"></div>
		<div class="container">
			<div class="row">
				<div class="col-lg-5 col-md-12 col-12 wow fadeInUp" data-wow-delay=".3s">
					<div class="evalution-form">
						<h5>Free Case Evalution</h5>
						<form action="index.html">
							<input type="text" placeholder="Your Name">
							<input type="email" placeholder="Your E-mail">
							<input type="tel" placeholder="Your Phone Number">
							<input type="text" placeholder="Subject">
							<textarea name="message" cols="30" rows="10" placeholder="Your Message"></textarea>
							<input class="main-btn" type="submit" value="Submit Now">
						</form>
					</div>
				</div>
				<div class="offset-lg-1 col-lg-6 col-md-12 col-12 wow fadeInDown" data-wow-delay=".4s">
					<div class="section-title mt-50">
						<h6>We Are Here</h6>
						<h2>Get a Quick Response & Legal Advice from <b>lawyer</b></h2>
						<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem recusandae voluptatibus dolores, molestiae distinctio earum dolorum, alias amet officia reprehenderit.
						</p>
						<h5>Contact Us</h5>
						<p class="tel">+88-148-265-102</p>
					</div>
				</div>
			</div>
		</div>
	</div> -->

	<!-- Team Section-->

	<!-- <div class="team-area section-padding pb-150">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="section-title text-center">
                        <h6>Meet Our Lawyer</h6>
                        <h2>Qualified <b>Attorneys</b></h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6 col-12 wow fadeInLeft my-5 pb-5 " data-wow-delay=".2s" v-for="attorney in attorneys.slice(0,4)" :key="attorney.id">
					<div class="single-team-member">
						<div class="team-member-bg" :style="'background-image:url('+attorney.image+')'">
							<div class="team-content">
								<div class="team-title">
									
									<router-link :to="{name:'AttorneyDetails', params:{ id: attorney.id} }">{{attorney.name}}</router-link>
								</div>
								<div class="team-subtitle">
									<p>{{attorney.designation}}</p>
								</div>
							</div>
							<div class="team-social">
								<ul>
									<li><a href="#"><i class="lab la-facebook"></i></a></li>
									<li><a href="#"><i class="lab la-twitter"></i></a></li>
									<li><a href="#"><i class="lab la-linkedin"></i></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>        
            </div>
        </div>
    </div> -->

	<!-- Testimonial Section -->

	<!-- <TestimonialOne/> -->

	<!-- Blog Section-->

	<!-- <div class="blog-area gray-bg section-padding">
		<div class="container">
			<div class="row">
				<div class="col-lg-6 col-md-12 col-12">
					<div class="section-title">
						<h6>Blog</h6>
						<h2>Our Latest <b>Articles</b> </h2>
					</div>
				</div>

				<div class="col-lg-6 text-right">

				</div>
			</div>
			<div class="row">
				<div class="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay=".4s" v-for="blog in blogs.slice(0,3)" :key="blog.id">
					<div class="single-blog-item">
						<div class="blog-bg">
							<img :src="blog.images.banner_image" :alt="blog.title">
						</div>
						<div class="blog-content">
							<p class="blog-meta"><i class="las la-user-circle"></i>{{blog.author}} | <i class="las la-calendar-check"></i>{{blog.date}}</p>
							<h5><router-link :to="{name:'BlogDetails', params:{ id: blog.id } }">{{blog.title}} </router-link>
							</h5>
							<p>{{blog.short_description.substr(0,60)}}....</p>
							<router-link :to="{name:'BlogDetails', params:{ id: blog.id } }" class="read-more">Read More</router-link>
						</div>

					</div>
				</div>


			
			</div>
		</div>
	</div> -->

</template>

<script>
import axios from 'axios'

// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import TestimonialOne from '@/components/TestimonialOne.vue'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

export default {
    name: 'Home',
    components : {Navbar,  Swiper, SwiperSlide,TestimonialOne},
    data(){
        return {
            modules: [Navigation, Pagination, A11y, Autoplay],
            swiperOptions: {
                breakpoints: {
                354: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                575: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                767: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                },

                990: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                },
            },
			blogs : [],
			attorneys : [],
			choose_us : [],
			cases : [],
			services : []
        }
    },

	async mounted(){
        //scrolling to top of the window
        window.scrollTo(0, 0);
				
		// let fetchedBlogData = await axios.get("/assets/data/blogs.json");
        // this.blogs = fetchedBlogData.data.blogs;

		// let fetchedAttorneyData = await axios.get("/assets/data/attorneys.json");
		// this.attorneys = fetchedAttorneyData.data.attorneys;

		// let fetchedCases = await axios.get('/assets/data/cases.json');
		// this.cases = fetchedCases.data.cases;

		// let fetcheServicesdData = await axios.get("/assets/data/services.json");
        // this.services = fetcheServicesdData.data.services;

		let fetchedData = await axios.get('/assets/data/choose_us.json');
		this.choose_us = fetchedData.data.choose_us;

	}
}
</script>