<template>
  <router-view/>
  <Footer/>
</template>

<script>

// @ is an alias to /src
import Footer from '@/components/Footer.vue'

export default {
    name: 'About',
    components : { Footer},
}
</script>

<style>

</style>
